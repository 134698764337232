import React from 'react';
import * as portfolioStyles from '../../components/portfolio/portfolio.module.css';

export default class Portfolio extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <p className={portfolioStyles.text}>
            <a className={portfolioStyles.link} href="/portfolio/main">Back to Math/CS Portfolio Homepage</a>
        </p>

    }
}
