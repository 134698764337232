// extracted by mini-css-extract-plugin
export var content = "portfolio-module--content--fx1jf";
export var header = "portfolio-module--header--F6OTD";
export var light = "portfolio-module--light--vlY4J";
export var line = "portfolio-module--line--3gh0V";
export var link = "portfolio-module--link--8ajes";
export var mini = "portfolio-module--mini--1epa7";
export var section = "portfolio-module--section--N9n9g";
export var subsection = "portfolio-module--subsection--Ax+1x";
export var text = "portfolio-module--text--8b8my";
export var thinline = "portfolio-module--thinline--YkyJA";
export var title = "portfolio-module--title--o5rl5";