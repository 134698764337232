import React from 'react';
import { Helmet } from "react-helmet"
import NavigationBar from '../../components/navigation_bar';
import * as portfolioStyles from '../../components/portfolio/portfolio.module.css';
import BackButton from '../../components/portfolio/back_button'
import algaeBloomsSmall from '../../images/portfolio/AlgaeBloomsSmall.png';
import dlaSmall from '../../images/portfolio/MC_dla2_small.png';
import algaeBlooms from '../../images/portfolio/AlgaeBlooms.png';
import dla from '../../images/portfolio/MC_dla2.png';

export default class Portfolio extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>DLA projects</title>
            </Helmet>
            <NavigationBar />

            <div className={portfolioStyles.content}>
                <h1 className={portfolioStyles.title}>Diffusion-Limited Aggregation Projects</h1>
                <hr className={portfolioStyles.line} />
                <BackButton />
                <p className={portfolioStyles.text}>
                    One particular phenomenon that I have written simulations for a few times
                    is called "Diffusion-Limited Aggregation", in which particles undergoing
                    Brownian motion aggregate together.
                </p> <p className={portfolioStyles.text}>
                    This is a real phenomenon that typically happens when particles of a solution
                    start crystalising around a seed particle or surface. Here's a an example from
                    the <a className={portfolioStyles.link} href="https://en.wikipedia.org/wiki/Diffusion-limited_aggregation">Wikipedia page</a> for this phenomenon
                    created with a copper sulfate solution:
                </p>
                <a href="https://en.wikipedia.org/wiki/Diffusion-limited_aggregation#/media/File:DLA_Cluster.JPG" target="_blank">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/DLA_Cluster.JPG/320px-DLA_Cluster.JPG" />
                </a>
                <p className={portfolioStyles.text}>
                    This seemed like a fun project to simulate in code, so on a long plane ride,
                    I decided to write a java program to simulate it, and the results
                    ended up being quite pretty:
                </p>
                <a href={algaeBlooms} target="_blank"><img src={algaeBloomsSmall} /></a>
                <p className={portfolioStyles.text}>
                    And, revisiting this idea much later, I wrote a feature for HBot that could simulate
                    a 3D DLA in Minecraft for any parameters specified by the user, from the blocks that
                    can act as a seed to the way the solution changes over time. After quite a bit of optimization
                    to get it to run in a reasonable amount of time, here is an example of what you can produce
                    with it:
                </p>
                <a href={dla} target="_blank"><img src={dlaSmall} /></a>
                <p className={portfolioStyles.text}>
                    You can click on any of the images to view the full image.
                </p>
                <hr className={portfolioStyles.thinline} />
            </div>
        </div>
    }
}
